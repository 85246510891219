<template>
  <div class="mt-10">
    <v-text-field outlined solo placeholder="Username" v-model="user" label="Username" ></v-text-field>
    <v-text-field outlined solo placeholder="Password" type="password" v-model="password" label="Password"></v-text-field>
    <v-btn ripple @click="signIn">Login</v-btn>
  </div>
</template>

<script>
  import store from "@/store/store";
  import { Auth } from 'aws-amplify'
  export default {
    name: "Login.vue",
    data() {
      return {
        user: "",
        password: ""
      }
    },
    methods: {
      async signIn() {
        try {
          const user = await Auth.signIn(this.user, this.password);
          if (user.signInUserSession !== null) {
            let username = ""
            await Auth.currentUserInfo().then(response => {
              username = response.username;
            })
            store.commit("setAuthenticated", true)
            console.log(username)

            store.commit("setAuthenticatedUser", username)
            localStorage.setItem("vue-token", user.signInUserSession.accessToken.jwtToken)
            localStorage.setItem("vue-refresh", user.signInUserSession.refreshToken.token)
            await this.$router.push('/');
          }
        } catch (e) {
          console.log('error signing in', e);
        }
      }
    }
  }
</script>

<style scoped>

</style>